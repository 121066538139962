import React from "react"
import Layout from "../components/layout"
import MainImage from "../components/mainImage"
import CardImageContainer from "../components/cardImageContainer"
import montagueCounty from "../../static/systems/montague-county.png"
import mountrailCounty from "../../static/systems/mountrail-county.png"
import reevesCounty from "../../static/systems/reeves-county.png"
import wyoming from "../../static/systems/wyoming.png"
import miBG from "../../static/systems/mi-bg.png"
import { Link } from "gatsby"
import MontagueCountyTexas from "../../static/pdf-maps/MontagueCountyTexas.pdf"
import ReevesCountyTexas from "../../static/pdf-maps/ReevesCountyTexas.pdf"
import Mountrail from "../../static/pdf-maps/Mountrail.pdf"
import WyomingPecan from "../../static/pdf-maps/PECAN_WY.pdf"
import Verde36 from "../../static/pdf-maps/Verde36.pdf"
import verde36System from "../../static/systems/Verde36.png"

import { Helmet } from "react-helmet"

export default function systems(props) {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>pecan pipeline - Gas Gathering and Processing Systems</title>
      </Helmet>
      <MainImage header="Gas Gathering and Processing Systems" src={miBG} />
      <h2 className="page-content-title fs5">Locations and Routes</h2>
      <div className="page-content-container systems-page">
        <div className="page-card fs2 part1">
          <h2 className="page-card-header fs4">
            North Dakota Gas Gathering System
          </h2>
          <CardSeparator marginTop={"5px"} />
          <div className="card-content">
            <div className="meta-content">
              Name:{" "}
              <span className="card-value-highlight">
                Stanley Gas Gathering System
              </span>
              <br />
              State: <span className="card-value-highlight">North Dakota</span>
              <br />
              County:{" "}
              <span className="card-value-highlight">Mountrail County</span>
              <br />
              <br />
              Pecan Pipeline gathers and compresses natural gas <br />
              produced in and around Mountrail County, North <br />
              Dakota.
            </div>
            <Link className="card-content-link-highlight" to="/map">
              View more
            </Link>
            <CardImageContainer
              title="Mountrail County"
              text="North Dakota"
              src={mountrailCounty}
              path={Mountrail}
            />
          </div>
        </div>

        <div className="page-card fs2 part2">
          <div className="card-content">
            <h2 className="page-card-header fs4">
              Texas Gas Gathering Systems
            </h2>
            <CardSeparator marginTop={"5px"} />
            <div className="meta-content">
              Name:{" "}
              <span className="card-value-highlight">Kripple Kreek System</span>
              <br />
              State: <span className="card-value-highlight">Texas</span>
              <br />
              County:{" "}
              <span className="card-value-highlight">Montague County</span>
              <br />
              <br />
              Pecan Pipeline gathers, conditions, compresses and processes
              natural gas produced in and around Montague County, Texas.
            </div>
            <Link className="card-content-link-highlight" to="/map">
              View more
            </Link>
            <CardImageContainer
              title="Montague County"
              text="Texas"
              src={montagueCounty}
              path={MontagueCountyTexas}
            />
            <CardSeparator />
            Name:{" "}
            <span className="card-value-highlight">
              Zena Gas Gathering System
            </span>
            <br />
            State: <span className="card-value-highlight">Texas</span>
            <br />
            County: <span className="card-value-highlight">Reeves County</span>
            <br />
            <br />
            Pecan Pipeline gathers and compresses natural gas produced in and
            around Reeves County, Texas.
            <br />
            <br />
            <Link className="card-content-link-highlight" to="/map">
              View more
            </Link>
            <CardImageContainer
              title="Reeves County"
              text="Texas"
              src={reevesCounty}
              path={ReevesCountyTexas}
            />
            <CardSeparator />
            Name:{" "}
            <span className="card-value-highlight">
              Verde Natural Gas Pipeline System
            </span>
            <br />
            State: <span className="card-value-highlight">Texas</span>
            <br />
            County:{" "}
            <span className="card-value-highlight">
              Webb, Duval, Jim Wells, & Nueces
            </span>
            <br />
            <br />
            Pecan Pipeline gathers, conditions, compresses and processes natural
            gas produced in and around Webb, Duval, Jim Wells, & Nueces County
            <br />
            <br />
            <Link className="card-content-link-highlight" to="/map">
              View more
            </Link>
            <CardImageContainer
              title="Webb, Duval, Jim Wells, & Nueces Counties"
              text="Texas"
              src={verde36System}
              path={Verde36}
            />
          </div>
        </div>

        <div className="page-card fs2 part2">
          <div className="card-content">
            <h2 className="page-card-header fs4">
              Wyoming Gas Gathering Systems
            </h2>
            <CardSeparator marginTop={"5px"} />
            <div className="meta-content">
              Name:{" "}
              <span className="card-value-highlight">
                Pecan Wyoming Gathering System
              </span>
              <br />
              State: <span className="card-value-highlight">Wyoming</span>
              <br />
              Counties:{" "}
              <span className="card-value-highlight">
                Johnson, Campbell, and Converse Counties
              </span>
              <br />
              <br />
              Pecan Pipeline gathers and compresses natural gas produced in and
              around Johnson, Campbell, and Converse Counties, Wyoming.
            </div>
            <Link className="card-content-link-highlight" to="/map">
              View more
            </Link>
            <CardImageContainer
              title="Johnson, Campbell, and Converse Counties"
              text="Wyoming"
              src={wyoming}
              path={WyomingPecan}
            />
          </div>
        </div>
      </div>

      <h2 className="page-content-title fs5">Sustainability</h2>
      <div className="page-content-container systems-page">
        <div className="page-card fs2">
          Pecan Pipeline Company and Pecan Pipeline (North Dakota), Inc. are
          committed to sustainability and responsible stewardship of all of
          their resources. For more information, please see EOG Resources,
          Inc.’s Sustainability Report available at{" "}
          <a
            className="link-highlight-3"
            href="https://www.eogresources.com/sustainability"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.eogresources.com/sustainability
          </a>
          .
        </div>
      </div>
    </Layout>
  )
}

function CardSeparator(props) {
  return (
    <hr
      style={{
        width: "100%",
        border: "1px solid rgba(51,73,95,0.25)",
        marginTop: props.marginTop || "18px",
        marginBottom: "36px",
      }}
    />
  )
}
