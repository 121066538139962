import React, { useState, Fragment } from "react"

export default function CardImageContainer({
  src = "",
  title = "",
  text = "",
  path = "#",
}) {
  const [isHovered, setIsHovered] = useState(false)
  return (
    <div
      style={{ backgroundImage: `url(${src})` }}
      className="card-image-container"
      role="link"
      tabIndex={0}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="card-image-text-container fbx-cc">
        {!isHovered ? (
          <Fragment>
            <p className="card-image-container-title">{title}</p>
            <p className="card-image-container-text">{text}</p>
          </Fragment>
        ) : (
          <a
            className="card-image-container-text fbx-cc"
            href={path}
            target="_blank"
            rel="noopener noreferrer"
          >
            View PDF Map
          </a>
        )}
      </div>
    </div>
  )
}
